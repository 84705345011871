import { observer } from "mobx-react";
import React from "react";

import EditDialog from "../../components/EditDialog";
import SelectField from "../../components/SelectField";

import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import { Link } from "@material-ui/core";
import { baseUrl } from "../../utils/Client";

const store = Stores.load("payments", "createdDate");

class PaymentEdit extends React.Component {
  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};
    let status = [
      { label: "Cancelado", value: "CANCELED" },
      { label: "Aguardando aprovação", value: "PENDING" },
      { label: "Aprovado", value: "APPROVED" },
      { label: "Finalizado", value: "FINISHED" },
    ];
    this.taxCoupon = store.edit != null ? this.item["taxCoupon"] : null;

    return (
      <EditDialog
        title="Editar Status do Pagamento"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          {this.taxCoupon && (
            <div className="columnStyles">
              <h4 style={styles.liTexts}>Dados do Cupom Fiscal:</h4>
              <p style={styles.liTexts}>CPF: {this.taxCoupon["cpf"]}</p>
              <p style={styles.liTexts}>E-mail: {this.taxCoupon["email"]}</p>
              <p style={styles.liTexts}>Telefone: {this.taxCoupon["phone"]}</p>
            </div>
          )}
          <div
            className={
              this.taxCoupon ? "columnStyles column02" : "columnStyles"
            }
          >
            { !!this.item.hash &&
              <div style={{ marginBottom: 20 }}>
                <Link
                  href={`${process.env.NODE_ENV !== "production" 
                      ? "http://localhost:3000" 
                      : "https://app.goldenbelle.com.br"
                    }/payment-link/${this.item.hash}`
                  }
                  target="_blank"
                  color="inherit"
                  style={{ display: "inline-block" }}
                >
                  Clique para acessar o link de pagamento
                </Link>
              </div>
            }
            <SelectField
              label="&nbsp; Status"
              field="status"
              item={this.item}
              list={status}
              variant="outlined"
            />
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(PaymentEdit);

const styles = {
  liTexts: {
    margin: 0,
  },
};
