import { extendObservable, action } from "mobx";

import { post, get, put, del, jsonParser } from "../utils/Client";

import { SIZE } from "../utils/Constants";

export default class UserStore {
  constructor(entity, sortColumn = "name") {
    const url = `/${entity}`;

    extendObservable(this, {
      queryField: "",

      searchAll: false,

      missingGoldenBellePixKey: false,

      missingGoldenBeautyPixKey: false,

      withGoldenBellePixKey: false,

      withGoldenBeautyPixKey: false,

      sort: { column: sortColumn, ascending: true },

      size: SIZE,

      page: 0,

      count: 0,

      list: [],

      selected: [],

      edit: null,

      rules: {},

      errors: {},

      open: action(() =>
        get(url + `/${this.selected[0].id}`)
          .then(jsonParser)
          .then((data) => (this.edit = data))
      ),

      new: action((empty = {}) => (this.edit = empty)),

      create: action(() => post(url, { body: this.edit }).then(jsonParser)),

      update: action(() =>
        put(url + `/${this.edit.id}`, { body: this.edit }).then(jsonParser)
      ),

      resetErrors: action(() =>
        Object.keys(this.rules).forEach((k) => delete this.errors[k])
      ),

      validate: action(() =>
        Object.keys(this.rules).forEach((k) => {
          const updateError = (rule) => {
            let msg = rule(k, this.edit);
            if (msg) {
              this.errors[k] = msg;
            }
          };
          if (Array.isArray(this.rules[k])) {
            this.rules[k].forEach(updateError);
          } else {
            updateError(this.rules[k]);
          }
        })
      ),

      save: action(
        (onSave) =>
          new Promise((resolve, reject) => {
            this.resetErrors();
            this.validate();
            if (Object.keys(this.errors).length > 0) {
              reject("Erro de validação.");
            } else if (this.edit.id != null) {
              return this.update().then((data) => {
                if (onSave) {
                  onSave(data);
                }
                resolve(data);
              });
            } else {
              return this.create().then((data) => {
                if (onSave) {
                  onSave(data);
                }
                resolve(data);
              });
            }
          })
      ),

      query: action(() =>
        get(
          `${url}/list` +
            `?query=${this.queryField}` +
            `&all=${this.searchAll}` +
            `&size=${this.size}` +
            `&page=${this.page}` +
            `&sort=${this.sort.column}` +
            `&order=${this.sort.ascending ? "ASC" : "DESC"}` + 
            `&missingGoldenBellePixKey=${this.missingGoldenBellePixKey ? "1" : "0"}` +
            `&withGoldenBellePixKey=${this.withGoldenBellePixKey ? "1" : "0"}` +
            `&missingGoldenBeautyPixKey=${this.missingGoldenBeautyPixKey ? "1" : "0"}` +
            `&withGoldenBeautyPixKey=${this.withGoldenBeautyPixKey ? "1" : "0"}`
        )
          .then(jsonParser)
          .then((data) => {
            this.page === 0
              ? (this.list = data.content)
              : this.list.push.apply(this.list, data.content);
            this.count = data.count;
          })
      ),

      delete: action(
        () =>
          new Promise((resolve, reject) =>
            this.selected.map((i) =>
              del(url + `/${i.id}`)
                .then((res) => this.selected.shift())
                .catch((e) => reject(e))
                .then(() => {
                  if (this.selected.length === 0) resolve();
                })
            )
          )
      ),
    });
  }
}
