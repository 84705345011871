import React from "react";
import { observer } from "mobx-react";

import List from "../../components/List";

import AuthenticationStore from "../../stores/AuthenticationStore";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";

import Edit from "./Edit";
import SearchField from "../../components/SearchField";
import SortPanel from "../../components/SortPanel";

const store = Stores.load("users");

class Users extends React.Component {
  componentDidMount() {
    UIStore.init("Revendedores", store);
  }

  handleDelete = () => {
    let count = store.selected.length;

    UIStore.showConfirmDialog(
      `Deseja remover ${count} revendedores?`,
      "Remover Revendedores"
    ).then((b) => {
      if (b) {
        store
          .delete()
          .then(() => store.query())
          .catch(() => alert("Erro!"));
      }
    });
  };

  handleFilter = (
    searchAll, 
    missingGoldenBellePixKey, 
    withGoldenBellePixKey,
    missingGoldenBeautyPixKey,
    withGoldenBeautyPixKey
  ) => {
    store.page = 0;
    store.searchAll = searchAll;
    store.missingGoldenBellePixKey = missingGoldenBellePixKey;
    store.withGoldenBellePixKey = withGoldenBellePixKey;
    store.missingGoldenBeautyPixKey = missingGoldenBeautyPixKey;
    store.withGoldenBeautyPixKey = withGoldenBeautyPixKey;
    store.query();
  };

  render() {
    let toggleFilters = (
      <>
        <ul className="filters-list">
          <li>
            <div
              className={(!store.searchAll && !store.missingGoldenBellePixKey && !store.missingGoldenBeautyPixKey && !store.withGoldenBellePixKey && !store.withGoldenBeautyPixKey) ? "activated" : ""}
              onClick={() => this.handleFilter(false, false, false, false, false)}
            >
              Liberados
            </div>
          </li>
          <li>
            <div
              className={(store.searchAll && !store.missingGoldenBellePixKey && !store.missingGoldenBeautyPixKey && !store.withGoldenBellePixKey && !store.withGoldenBeautyPixKey) ? "activated" : ""}
              onClick={() => this.handleFilter(true, false, false, false, false)}
            >
              Todos
            </div>
          </li>
        </ul>
        <ul className="filters-list">
          <li>
            <div
              className={store.withGoldenBellePixKey ? "activated" : ""}
              onClick={() => this.handleFilter(false, false, true, false, false)}
            >
              Com Pix GoldenBelle
            </div>
          </li>
          <li>
            <div
              className={store.missingGoldenBellePixKey ? "activated" : ""}
              onClick={() => this.handleFilter(false, true, false, false, false)}
            >
              Sem Pix GoldenBelle
            </div>
          </li>
        </ul>
        <ul className="filters-list">
          <li>
            <div
              className={store.withGoldenBeautyPixKey ? "activated" : ""}
              onClick={() => this.handleFilter(false, false, false, false, true)}
            >
              Com Pix GoldenBeauty
            </div>
          </li>
          <li>
            <div
              className={store.missingGoldenBeautyPixKey ? "activated" : ""}
              onClick={() => this.handleFilter(false, false, false, true, false)}
            >
              Sem Pix GoldenBeauty
            </div>
          </li>
        </ul>
      </>
    );

    let canEdit;
    if (AuthenticationStore.me.role === "MANAGER") {
      canEdit = false;
    } else {
      canEdit = true;
    }

    return (
      <>
        <List
          store={store}
          data={store.list.map((i) => ({
            item: i,
            title: i.name + " (" + i.email + ")",
            content: (
              <>
                Adicionado em:{" "}
                {i.createdDate ? i.createdDate.formatTimeStamp() : ""}
                <br />
                Total: {i.value ? (i.value / 100).formatMoney() : "R$0,00"}
              </>
            ),
          }))}
          status={store.list.map((i) => ({
            id: i.id,
            label: i.enabled ? "Liberado" : "Desativado",
            class: i.enabled ? "finished-status" : "canceled-status",
          }))}
          rightPanel={
            <>
              <SortPanel
                store={store}
                filters={{
                  name: "Nome",
                  code: "Código",
                  email: "Email",
                  createdDate: "Data de Adição",
                }}
              />
              <SearchField store={store} />
            </>
          }
          leftPanel={toggleFilters}
          onAdd={
            canEdit
              ? () =>
                  store.new({
                    name: "",
                    code: "",
                    email: "",
                    cpf: "",
                    phone: "",
                    role: "",
                  })
              : null
          }
          onEdit={canEdit ? () => store.open() : null}
          onDelete={canEdit ? () => this.handleDelete() : null}
        />

        <Edit onSave={() => AuthenticationStore.testAuthenticated()} />
      </>
    );
  }
}

export default observer(Users);
