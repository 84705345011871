import moment from "moment";

const REQUIRED = (field, item) =>
  !item[field] || item[field] === "" || item[field] === 0
    ? "O valor é requerido"
    : null;

const AFTER_TODAY = (field, item) =>
  REQUIRED(field, item) || moment().diff(item[field], "days") > 0
    ? "A data selecionada deve estar no futuro"
    : null;

const LESS_THAN = (otherField, label) => (field, item) =>
  item[field] >= item[otherField]
    ? `O valor deve ser menor que ${label}`
    : null;

const MORE_THAN = (otherField, label) => (field, item) =>
  item[field] <= item[otherField]
    ? `O valor deve ser maior que ${label}`
    : null;

const MORE_THAN_ZERO = (field, item) =>
  item[field] <= 0 ? "O valor deve ser maior que zero" : null;

const AT_LEAST_ONE = (field, item) =>
  !item[field] || !Array.isArray(item[field]) || item[field].length === 0
    ? "É necessário adicionar ao menos um valor."
    : null;

const validate = async (rules, item) => {
  let errors = {};
  const fields = Object.keys(rules);
  for (let i = 0; i < fields.length; i = i + 1) {
    let field = fields[i];
    let rule = rules[field];
    if (Array.isArray(rule)) {
      for (let j = 0; j < rule.length; j = j + 1) {
        let msg = await rule[j](field, item);
        if (msg) {
          errors[field] = msg;
          break;
        }
      }
    } else {
      let msg = await rule(field, item);
      if (msg) {
        errors[field] = msg;
      }
    }
  }
  return errors;
};

const isValid = (errors) => Object.keys(errors).length === 0;

const getCardFlag = (number) => {
  let cardnumber = number.replace(/[^0-9]+/g, "");

  let cards = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Master: /^5[1-5][0-9]{14}/,
    Amex: /^3[47][0-9]{13}/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}/,
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
  };

  for (let flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return false;
};

export {
  REQUIRED,
  AFTER_TODAY,
  LESS_THAN,
  MORE_THAN,
  MORE_THAN_ZERO,
  AT_LEAST_ONE,
  validate,
  isValid,
  getCardFlag,
};
