import React, { useEffect, useState } from "react";
import { get, jsonParser, post } from "../../utils/Client";
import { Button, CircularProgress, InputAdornment } from "@material-ui/core";
import { PAYMENT_LINK, PAYMENT_STATUS, PAYMENT_STYLES } from "./Consts";
import TextField from "../../components/TextField";
import { CalendarTodayOutlined, CreditCard, Lock, Person, Visibility, VisibilityOff } from "@material-ui/icons";
import { REQUIRED, getCardFlag, isValid, validate } from "../../utils/Validation";
import logo from "../../assets/logo.svg";


export default function PaymentURL({ match }) {
  const [form, setForm] = useState({
    cardNumber: "",
    holder: "",
    expirationDate: "",
    brand: "",
    securityCode: "",
  });
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState({});
  const [secret, setSecret] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  
  useEffect(() => {
    const load = async (hash) => {
      try{
        setLoading(true);
        setHasError(false);
        const res = await get(`/payments/hash/${hash}`, {}, true).then(jsonParser);
        setPayment(res);
      } catch(e){
        console.log(!!e.message ? e.message : e);
        setHasError(true);
      } finally{
        setLoading(false);
      }
    }

    if(!!match && !!match.params && loading){
      const { hash } = match.params;
      load(hash);
    }
  }, [match, loading]);
  
  const submit = async () => {
    try{
      setSubmitting(true);
      setHasSubmitError(false);

      const data = {
        ...form,
        ...{
          cardNumber: form.cardNumber.replace(/[^0-9]+/g, ""),
          brand: getCardFlag(form.cardNumber),
        }
      }
      const rules = {
        cardNumber: REQUIRED,
        holder: REQUIRED,
        expirationDate: REQUIRED,
        brand: REQUIRED,
        securityCode: REQUIRED,
      };
      
      const errors = await validate(rules, data);
      setErrors(errors);
      if(isValid(errors)){
        const res = await post(`/payments/link/pay/${payment.hash}`, { body: data }, true).then(jsonParser);
        setPayment(res);
      }
    } catch(e){
      console.log(!!e.message ? e.message : e);
      setHasSubmitError(true);
    } finally{
      setSubmitting(false);
    }
  }

  const getInstallments = (value, installments) => {
    console.log(value.toFixed(2))
    if (installments === 1) {
      return `À vista - ${value.formatMoney()}`;
    } else {
      const num = value / installments;
      return "Em " + installments + "x de " + num.formatMoney();
    }
  };

  return(
    <div style={styles.body}>
      <img 
        src={logo}
        style={styles.logo}
      />
      <div style={styles.title}>
        Pagamento via link
      </div>
      { loading &&
        <CircularProgress style={styles.loadingSpinner} />
      }
      { (!loading && hasError) && (
        <>
          <div style={styles.errorLabel}>
            Houve um erro na conexão com o servidor
          </div>
          <Button
            onClick={() => setLoading(true)}
            style={styles.btn}
          >
            Reconectar
          </Button>
        </>
      )}
      { !!payment && (
        <>
          <div style={PAYMENT_STYLES[payment.status]}>
            {PAYMENT_STATUS[payment.status]}
          </div>
          <div style={{ height: 10 }} />
          <div style={styles.price}>
            { (payment.amount / 100).formatMoney()}
          </div>
          <div style={{ height: 10 }} />
          <div style={styles.price}>
            { getInstallments(payment.amount / 100, payment.installments) }
          </div>
          <div style={{ height: 10 }} />
          { (payment.status === "PENDING" && !!payment.responseId) &&
            <div style={PAYMENT_STYLES["PENDING"]}>
              O pagamento está sendo processado
            </div>
          }
          { (payment.status === "PENDING" && !payment.responseId) && (
            <>
              <TextField 
                disabled={submitting}
                errors={errors}
                field="cardNumber"
                item={form}
                label={`Número do ${payment.type === PAYMENT_LINK ? "cartão de crédito" : ""}`}
                style={styles.inputOutline}
                onChange={(e) => {
                  e.persist();
                  setForm(f => ({ 
                    ...f, 
                    ...{ cardNumber: e.target.value.formatCreditCard() } 
                  }));
                }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCard color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField 
                disabled={submitting}
                errors={errors}
                field="holder"
                item={form}
                label="Nome do titular"
                style={styles.inputOutline}
                onChange={(e) => {
                  e.persist();
                  setForm(f => ({ 
                    ...f, 
                    ...{ holder: e.target.value } 
                  }));
                }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField 
                disabled={submitting}
                errors={errors}
                field="expirationDate"
                item={form}
                label="Validade"
                style={styles.inputOutline}
                onChange={(e) => {
                  e.persist();
                  setForm(f => ({ 
                    ...f, 
                    ...{ expirationDate: e.target.value.formatExpirationDate(false) } 
                  }));
                }}
                onBlur={() => 
                  setForm(f => ({ 
                    ...f, 
                    ...{ expirationDate: form.expirationDate.formatExpirationDate(true) } 
                  }))
                }
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayOutlined color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField 
                disabled={submitting}
                errors={errors}
                field="securityCode"
                item={form}
                label="CVV"
                style={styles.inputOutline}
                onChange={(e) => {
                  e.persist();
                  setForm(f => ({ 
                    ...f, 
                    ...{ securityCode: e.target.value.formatCVV() } 
                  }));
                }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setSecret(v => !v)}
                      >
                        { secret ? <VisibilityOff color="primary" /> : <Visibility color="primary" /> }
                      </div>
                    </InputAdornment>
                  ),
                }}
                type={secret ? "password" : undefined}
              />
            </>
          )}
          { hasSubmitError &&
            <div style={styles.submitError}>
              Houve um erro no envio do pagamento. Tente novamente mais tarde.
            </div>
          }
          { (payment.status === "PENDING" && !submitting) && (
            <Button
              onClick={submit}
              style={styles.btn}
              disabled={submitting}
            >
              Fazer o pagamento
            </Button> 
          )}
          { (payment.status === "PENDING" && submitting) && (
            <CircularProgress style={styles.loadingSpinner} />
          )}
        </>
      )}
      <div style={{ height: 100 }} />
    </div>
  )

}

const styles = {
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
    flexDirection: "column",
  },
  errorLabel: {
    fontSize: 18,
    color: "#000",
  },
  btn: {
    backgroundColor: "#00B7B3",
    color: "#FFF",
    fontWeight: "700",
  },
  loadingSpinner: {
    marginTop: 80,
  },
  inputOutline: {
    marginBottom: "20px",
    width: 300,
  },
  price: {
    backgroundColor: "#00aa00",
    borderRadius: 100,
    padding: 5,
    color: "#FFF",
    fontWeight: "700",
  },
  submitError: {
    color: "#000",
    marginTop: 20,
    marginBottom: 20,
    fontWeight: "700",
  },
  logo: {
    marginBottom: 20,
  },
  title: {
    color: "#00B7B3",
    marginBottom: 40,
    fontWeight: "700",
    fontSize: 20,
  },
}