import React from "react";
import { observer } from "mobx-react";
import UIStore from "../../stores/UIStore";

class Home extends React.Component {
  componentDidMount() {
    UIStore.init("Principal", null, null, false);
  }

  render() {
    return (
      <div className="holder">
        Bem vindo ao administrativo GoldenBelle!
      </div>
    );
  }
}

export default observer(Home);
