import { extendObservable, action } from "mobx";

import { get, form, post, jsonParser } from "../utils/Client";
import { history } from "../";

class AuthenticationStore {
  constructor() {
    extendObservable(this, {
      isAuthenticated: false,

      me: {},

      schemas: [],

      testAuthenticated: action(() =>
        get("/me")
          .then(res => {
            if (res.status === 401 || res.status === 403) {
              this.isAuthenticated = false;
              history.push("/login");
              return Promise.reject();
            } else {
              this.isAuthenticated = true;
              return res;
            }
          })
          .then(jsonParser)
          .then(data => {
            this.me = data;
          })
      ),

      login: action((email, password) =>
        form("/authenticate", {
          body: { email, password }
        })
          .then(res => {
            if (res.status === 403) {
              this.isAuthenticated = false;
              localStorage.apiToken = null;
              this.user = {};
              return Promise.reject("Usuário ou senha inválidos.");
            } else {
              this.isAuthenticated = true;
              return res.json();
            }
          })
          .then(data => {
            this.me = data.user;
            localStorage.apiToken = data.token;
          })
      ),

      register: action(
        (name, email, company, cpf, enabled, phone, role, password) =>
          post("/register", {
            body: { name, email, company, cpf, enabled, phone, role, password },
            credentials: "omit"
          })
      ),

      logout: action(() =>
        post("/logout").then(res => {
          this.isAuthenticated = false;
          this.user = {};
          localStorage.clear();
        })
      ),

      hasRole: action(
        role =>
          this.me.perfil &&
          this.me.perfil.autorizacoes.filter(a => a.nome === role).length > 0
      )
    });
  }
}

export default new AuthenticationStore();
