import React from "react";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import moment from "moment";

import List from "../../components/List";
import Edit from "./Edit";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import SortPanel from "../../components/SortPanel";
import FileDownload from "../../components/FileDownload";
import SelectPanel from "../../components/SelectPanel";
import DateRange from "../../components/DateRange";

const store = Stores.load("payments", "user.name");
const usersStore = Stores.create("users", "name");

class Payments extends React.Component {
  componentDidMount() {
    usersStore.size = 99999;
    usersStore.query();
    store.sort = { column: "createdDate", ascending: false };
    UIStore.init("Pagamentos", store);
  }

  getType = (type) => {
    if (type === "CreditCard") {
      return "Crédito";
    } else if(type === "PIX"){
      return "PIX";
    }
  };

  check = () =>
    store
      .check()
      .then(() => store.query())
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    let status = [
      { label: "Cancelado", value: "CANCELED" },
      { label: "Aguardando aprovação", value: "PENDING" },
      { label: "Aprovado", value: "APPROVED" },
      { label: "Finalizado", value: "FINISHED" },
    ];

    let data = store.list.map((i) => ({
      icon: (
        <Checkbox
          color="primary"
          checked={i.checked}
          onChange={(event) => {
            store.selected = [i];
            this.check();
          }}
          style={styles.checkBox}
        />
      ),
      checked: i.checked,
      item: i,
      important: i.taxCoupon
        ? {
            id: i.taxCoupon.id,
            label: "REQUER CUPOM FISCAL",
            class: "important-status",
          }
        : null,
      title:
        i.status !== "CANCELED" ? (
          (i.amount / 100).formatMoney()
        ) : (
          <strike>{(i.amount / 100).formatMoney()}</strike>
        ),
      content: (
        <>
          {i.user.name + " (" + i.user.code + ")"}
          <br />
          {moment(i.createdDate).format("DD/MM/YYYY HH:mm")}
          {i.status !== "CANCELED"
            ? i.installments === 1
              ? " – À vista (" + this.getType(i.type) + ")"
              : " – Parcelado em " + i.installments + "x"
            : ""}
        </>
      ),
    }));

    return (
      <>
        <List
          store={store}
          data={data}
          status={store.list.map((i) => ({
            id: i.id,
            label:
              i.status === "CANCELED"
                ? "Cancelado"
                : i.status === "APPROVED"
                ? "Aprovado"
                : i.status === "FINISHED"
                ? "Finalizado"
                : "Aguardando aprovação",
            class:
              i.status === "CANCELED"
                ? "canceled-status"
                : i.status === "APPROVED"
                ? "approved-status"
                : i.status === "FINISHED"
                ? "finished-status"
                : "pending-status",
          }))}
          leftPanel={
            <>
              <SelectPanel
                options={usersStore.list}
                store={store}
                entity="user"
                label="Revendedor(a)"
                getLabel={(u) => (u ? u.name : "Todos")}
              />
              <SelectPanel
                options={status}
                store={store}
                entity="status"
                label="Status"
                getLabel={(u) => (u ? u.label : "Todos")}
              />
              <DateRange label="Período" store={store} />
            </>
          }
          rightPanel={
            <>
              <FileDownload download={store.report} filename="Relatorio.pdf">
                Vendas
              </FileDownload>
              <SortPanel
                store={store}
                filters={{
                  "user.name": "Nome do Revendedor",
                  amount: "Valor",
                  createdDate: "Data de Realização",
                }}
              />
            </>
          }
          onEdit={() => store.open()}
        />
        <Edit onSave={() => store.query()} />
      </>
    );
  }
}

export default observer(Payments);

const styles = {
  checkBox: {
    marginRight: 5,
    padding: 3,
    alignSelf: "flex-start",
  },
};
