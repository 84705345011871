import React from "react";
import { render } from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { unregister } from "./serviceWorker";

import "./index.css";
import "./utils/Format";

import App from "./screens/App";
import Home from "./screens/Home";
import Payments from "./screens/Payments";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Users from "./screens/Users";
import PaymentURL from "./screens/PaymentURL";

export const history = createBrowserHistory();

render(
  <div>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/payment-link/:hash" component={PaymentURL} />
        <App>
          <Route exact path="/" component={Home} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/payments" component={Payments} />
        </App>
      </Switch>
    </Router>
  </div>,
  document.getElementById("root")
);
unregister();
