import { extendObservable } from "mobx";

import DefaultStore from "./DefaultStore";
import UserStore from "./UserStore";

class Stores {
  stores = {};

  extendsCustom = (store, entity) => {
    try {
      let custom = require("./" +
        entity.charAt(0).toUpperCase() +
        entity.slice(1) +
        "Store");
      extendObservable(store, custom.create(store));
    } catch (e) {}
  };

  load = (entity, sortColumn = "name") => {
    if (!this.stores[entity]) {
      if(entity === "users"){
        this.stores[entity] = new UserStore(entity, sortColumn);
      } else{
        this.stores[entity] = new DefaultStore(entity, sortColumn);
      }
      this.extendsCustom(this.stores[entity], entity);
    }
    return this.stores[entity];
  };

  create = (entity, sortColumn = "name") => {
    let store;
    if(entity === "users"){
      store = new UserStore(entity, sortColumn);
    } else{
      store = new DefaultStore(entity, sortColumn);
    }
    this.extendsCustom(store, entity);
    return store;
  };
}

export default new Stores();
