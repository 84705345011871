/*eslint no-extend-native: ["error", { "exceptions": ["Object", "Number", "String"] }]*/

Number.prototype.format = function(n, x, s, c) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

Number.prototype.formatMoney = function() {
  return "R$ " + this.format(2, 3, ".", ",");
};

Number.prototype.formatPercent = function(decimal = 2) {
  return this.format(decimal, 3, ".", ",") + "%";
};

Number.prototype.formatInteger = function() {
  return this.format(0, 3, ".", ",");
};

Number.prototype.formatDecimal = function() {
  return this.format(2, 3, ".", ",");
};

String.prototype.formatCpf = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 3);
  if (num.length > 3) res += "." + num.substr(3, 3);
  if (num.length > 6) res += "." + num.substr(6, 3);
  if (num.length > 9) res += "-" + num.substr(9, 2);
  return res;
};

String.prototype.formatCnpj = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 2);
  if (num.length > 2) res += "." + num.substr(2, 3);
  if (num.length > 5) res += "." + num.substr(5, 3);
  if (num.length > 8) res += "/" + num.substr(8, 4);
  if (num.length > 12) res += "-" + num.substr(12, 2);
  return res;
};

String.prototype.convertDate = function() {
  if (this.length > 0) {
    let p = this.split(/\D/g);
    return [p[2], p[1], p[0]].join("/");
  }
  return;
};

String.prototype.formatTimeStamp = function() {
  if (this.length > 0) {
    let ts = this.split("T");
    return ts[0].convertDate();
  }
  return;
};

String.prototype.formatDocument = function() {
  if (this.length >= 14) {
    return this.formatCnpj();
  } else {
    return this.formatCpf();
  }
};

String.prototype.formatRg = function() {
  var num = this;
  var res =
    num.substr(0, 2) +
    "." +
    num.substr(2, 3) +
    "." +
    num.substr(5, 3) +
    "-" +
    num.substr(8, 1);
  return res;
};

String.prototype.capitalize = function() {
  var word = this.toLowerCase();
  word = word.charAt(0).toUpperCase() + word.slice(1);
  return word;
};

String.prototype.formatPhone = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");

  var res = "(" + num.substr(0, 2);
  if (num.length > 2) res += ") " + num.substr(2, 4);
  if (num.length > 6) res += "-" + num.substr(6, 5);

  if (num.length > 10)
    res =
      "(" + num.substr(0, 2) + ") " + num.substr(2, 5) + "-" + num.substr(7, 4);

  return res;
};

String.prototype.maskNumber = function() {
  var num = this.replace(".", "");
  return num.split(/(?=(?:\d{3})+(?:,|$))/g).join(".");
};

String.prototype.formatCep = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 5);
  if (num.length > 5) res += "-" + num.substr(5, 3);
  return res;
};

String.prototype.formatDate = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 2);
  if (num.length > 2) res += "/" + num.substr(2, 2);
  if (num.length > 4) res += "/" + num.substr(4, 4);
  return res;
};

String.prototype.formatDateTime = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 2);
  if (num.length > 2) res += "/" + num.substr(2, 2);
  if (num.length > 4) res += "/" + num.substr(4, 4);
  if (num.length > 8) res += " " + num.substr(8, 2);
  if (num.length > 10) res += ":" + num.substr(10, 2);
  return res;
};

String.prototype.formatTime = function() {
  var num = this.replace(new RegExp("[^0-9]", "g"), "");
  var res = num.substr(0, 2);
  if (num.length > 2) res += ":" + num.substr(2, 2);
  if (num.length > 4) res += ":" + num.substr(4, 2);
  return res;
};

String.prototype.camelToDash = function() {
  return this.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

String.prototype.formatCreditCard = function () {
  if (this === "") return "";
  let num = this.replace(new RegExp("[^0-9]", "g"), "");
  let res = num.substr(0, 4);
  if (num.length > 4) res += " " + num.substr(4, 4);
  if (num.length > 8) res += " " + num.substr(8, 4);
  if (num.length > 12) res += " " + num.substr(12, 4);
  return res;
};

String.prototype.formatExpirationDate = function (completeYear) {
  if (this === "") {
    return "";
  } else if (completeYear && (this.length == 4 || this.length == 5)) {
    let num = this.replace(new RegExp("[^0-9]", "g"), "");
    let res = num.substr(0, 2);
    if (num.length > 2) res += "/20" + num.substr(2, 4);
    return res;
  } else {
    let num = this.replace(new RegExp("[^0-9]", "g"), "");
    let res = num.substr(0, 2);
    if (num.length > 2) res += "/" + num.substr(2, 4);
    return res;
  }
};

String.prototype.formatCVV = function () {
  if (this === "") {
    return "";
  } 
  return this.replace(new RegExp("[^0-9]", "g"), "").slice(0, 4);
};