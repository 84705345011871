const PIX = "PIX";
const CREDIT_CARD = "CreditCard";
const PAYMENT_LINK = "PAYMENT_LINK";

const PAYMENT_TYPES = {
  PIX: PIX,
  CREDIT_CARD: CREDIT_CARD,
  PAYMENT_LINK: PAYMENT_LINK,
};

const PAYMENT_TYPE_LABELS = {
  "CreditCard": "Cartão de crédito",
  PIX: "PIX",
  PAYMENT_LINK: "Link de pagamento",
};

const PAYMENT_OPTIONS = [
  { label: "Cartão de crédito", value: CREDIT_CARD },
  { label: "PIX", value: PIX },
  { label: "Link de pagamento - Crédito", value: PAYMENT_LINK },
];

const PAYMENT_STYLES = {
  FINISHED: {
    backgroundColor: "#7EB88E",
    padding: 5,
    borderRadius: 100,
    color: "#FFF",
    fontSize: 16,
    fontWeight: "700",
  },
  APPROVED: {
    backgroundColor: "#6D9DE7",
    padding: 5,
    borderRadius: 100,
    color: "#FFF",
    fontSize: 16,
    fontWeight: "700",
  },
  PENDING: {
    backgroundColor: "#E5BA49",
    padding: 5,
    borderRadius: 100,
    color: "#FFF",
    fontSize: 16,
    fontWeight: "700",
  },
  CANCELED: {
    backgroundColor: "#E35858",
    padding: 5,
    borderRadius: 100,
    color: "#FFF",
    fontSize: 16,
    fontWeight: "700",
  }
};

const PAYMENT_STATUS = {
  FINISHED: "Finalizado",
  APPROVED: "Aprovado",
  PENDING: "Aguardando pagamento",
  CANCELED: "Cancelado"
};

export {
  PIX, 
  CREDIT_CARD,
  PAYMENT_LINK,
  PAYMENT_TYPES,
  PAYMENT_TYPE_LABELS,
  PAYMENT_OPTIONS,
  PAYMENT_STYLES,
  PAYMENT_STATUS,
}