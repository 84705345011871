import { extendObservable, action } from "mobx";

class UIStore {
  constructor() {
    extendObservable(this, {
      title: null,
      queryStore: null,
      filter: false,
      onUpdate: null,

      asideOpened: true,
      acessoOpened: false,
      filterOpened: false,

      snackMessage: "",
      snackOpened: false,
      erro: "",

      confirmTitle: "",
      confirmMessage: null,
      confirmPromise: null,

      teste: { nome: "" },

      testeSelect: "",

      asideOpen: action(() => {
        this.asideOpened = !this.asideOpened;
        if (this.asideOpened && this.acessoOpened) this.acessoOpen();
      }),

      acessoOpen: action(() => {
        this.acessoOpened = !this.acessoOpened;
        if (this.acessoOpened && this.asideOpened) this.asideOpen();
      }),

      filterOpen: action(() => (this.filterOpened = !this.filterOpened)),

      init: action(
        (
          title = null,
          queryStore = null,
          filter = false,
          onUpdate = null
        ) => {
          this.title = title;
          this.queryStore = queryStore;
          this.filter = filter;
          this.onUpdate = onUpdate;
          document.title = `GoldenBelle${this.title ? " - " + this.title : ""}`;
          if (
            this.queryStore !== null &&
            (this.queryStore.page === undefined || this.queryStore.page === 0)
          ) {
            this.queryStore.query();
          }
        }
      ),

      showSnack: action(message => {
        this.snackMessage = message;
        if (this.snackMessage.length > 0) {
          this.snackOpened = true;
        }
      }),

      showConfirmDialog: action((message, title = "Confirme") => {
        this.confirmTitle = title;
        this.confirmMessage = message;

        var defer = () => {
          var res, rej;
          var promise = new Promise((resolve, reject) => {
            res = resolve;
            rej = reject;
          });
          promise.resolve = res;
          promise.reject = rej;
          return promise;
        };
        this.confirmPromise = defer();

        return this.confirmPromise;
      }),

      hideConfirmDialog: action(res => {
        this.confirmMessage = null;
        this.confirmPromise.resolve(res);
      })
    });
  }

  saveError = err => {
    this.showSnack("Erro ao salvar!");
  };

  redo = store =>
    store
      .query()
      .then(() => (store.edit = null))
      .then(() => (store.selected = []));
}

export default new UIStore();
